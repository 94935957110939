import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './DonationForm.css';
import SubmitButton from './SubmitButton';
import ErrorMessage from './ErrorMessage';
import ResetButton from './ResetButton';
import Field from './Field';
import CardField from './CardField';

const DonationForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [billingDetails, setBillingDetails] = useState({
    email: '',
    phone: '',
    name: '',
    address: null
  });
  const [billingAddress, setBillingAddress] = useState({
    line1: '',
    city: '',
    state: '',
    postal_code: ''
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement('card').focus();
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        ...billingDetails,
        address: billingAddress
      },
    });

    setProcessing(false);

    if (payload.error) {
      setError(payload.error);
    } else {
      console.dir(payload)
      setPaymentMethod(payload.paymentMethod);
    }
  };

  const reset = () => {
    setError(null);
    setProcessing(false);
    setPaymentMethod(null);
    setBillingDetails({
      email: '',
      phone: '',
      name: '',
      address: null
    });
    setBillingAddress({
      line1: '',
      city: '',
      state: '',
      postal_code: ''
    });
  };

  return paymentMethod ? (
    <div className="Result">
      <div className="ResultTitle" role="alert">
        Payment successful
      </div>
      <div className="ResultMessage">
        Thanks for trying Stripe Elements. No money was charged, but we
        generated a PaymentMethod: {paymentMethod.id}
      </div>
      <ResetButton onClick={reset} />
    </div>
  ) : (
    <form className="Form" onSubmit={handleSubmit}>
      <fieldset className="FormGroup">
        <Field
          label="Name"
          id="name"
          type="text"
          placeholder="Jane Doe"
          required
          autoComplete="name"
          value={billingDetails.name}
          onChange={(e) => setBillingDetails({...billingDetails, name: e.target.value})}
        />
        <Field
          label="Email"
          id="email"
          type="email"
          placeholder="janedoe@gmail.com"
          required
          autoComplete="email"
          value={billingDetails.email}
          onChange={(e) => setBillingDetails({...billingDetails, email: e.target.value})}
        />
        <Field
          label="Phone"
          id="phone"
          type="tel"
          placeholder="(123) 456-7890"
          required
          autoComplete="tel"
          value={billingDetails.phone}
          onChange={(e) => setBillingDetails({...billingDetails, phone: e.target.value})}
        />
      </fieldset>
      <fieldset className="FormGroup">
        <Field
          label="Address"
          id="address"
          type="text"
          placeholder="100 Main Street"
          required
          value={billingAddress.line1}
          onChange={(e) => setBillingAddress({...billingAddress, line1: e.target.value })}
        />
        <Field
          label="City"
          id="city"
          type="text"
          placeholder="Chicago"
          required
          value={billingAddress.city}
          onChange={(e) => setBillingAddress({...billingAddress, city: e.target.value })}
        />
        <Field
          label="State"
          id="state"
          type="text"
          placeholder="Illinois"
          required
          value={billingAddress.state}
          onChange={(e) => setBillingAddress({...billingAddress, state: e.target.value })}
        />
        <Field
          label="Zip"
          id="zip"
          type="number"
          placeholder="12345"
          required
          value={billingAddress.postal_code}
          onChange={(e) => setBillingAddress({...billingAddress, postal_code: e.target.value })}
        />
      </fieldset>
      <fieldset className="FormGroup">
        <CardField
          onChange={(e) => {
            setError(e.error);
            setCardComplete(e.complete);
          }}
        />
      </fieldset>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
      <SubmitButton processing={processing} error={error} disabled={!stripe}>
        Donate
      </SubmitButton>
    </form>
  );
};
export default DonationForm;
